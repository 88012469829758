<div class="chat__main">
  <app-chat-navigation
    [childMessageChatTitle]="parentMessageChatTitle"
    [childMessageChatId]="parentMessageChatId"
  ></app-chat-navigation>

  <div class="chat__conversation">
    <div class="chat__container">
      <div
        class="chat__top-fade"
        [class.theme-light]="params?.theme === 'light'"
      ></div>

      <div class="chat__output">
        <div class="chat__output-inner" id="llmContainer">
          <div *ngIf="chatDetails && chatDetails.length > 0">
            <ng-container
              *ngFor="
                let chat of chatDetails;
                let i = index;
                trackBy: trackByChatId
              "
            >
              <div class="chat__message-container">
                <!-- User Message -->
                <div
                  *ngIf="chat.actor === 'human'"
                  class="message__container message__container--user"
                >
                  <div class="message__avatar-container">
                    <div class="message__avatar message__avatar--human">
                      {{ userAvatar }}
                    </div>
                  </div>
                  <div
                    class="message__textbox message__textbox--human"
                    [innerHTML]="chat.message"
                  ></div>
                  <div *ngIf="chartVisible">
                    <div class="container__empty" style="height: 10px"></div>
                  </div>
                </div>

                <!-- AI Message -->
                <div
                  *ngIf="chat.actor === 'ai'"
                  class="message__container message__container--response"
                >
                  <div class="message__avatar-container">
                    <div class="message__avatar message__avatar--response">
                      {{ llmAvatar }}
                    </div>
                  </div>
                  <div class="chat__wrapper">
                    <div
                      class="message__textbox message__textbox--ai"
                      *ngIf="
                        (isAIMessage(chat.message) && chat.message) ||
                        (!loading && !error)
                      "
                    >
                      <app-chat-message-facts
                        [aiMessage]="isAIMessage(chat.message) && chat.message"
                        (citationClicked)="
                          showTheAricleView(
                            chat.message.companyName,
                            chat.message.companyType,
                            $event.article
                          )
                        "
                      ></app-chat-message-facts>
                      <div
                        *ngIf="
                          isAIMessage(chat.message) &&
                          (chat.message.source_documents_events?.length > 0 ||
                            chat.message.source_documents?.length > 0)
                        "
                      >
                        <h2
                          *ngIf="
                            !chat.message.companyName ||
                            chat.message.requested_entities?.length > 1
                          "
                          class="article__title"
                        >
                          Articles
                        </h2>
                        <h2
                          *ngIf="
                            chat.message.companyName &&
                            chat.message.companyName !== 'all' &&
                            chat.message.requested_entities?.length === 1
                          "
                          class="article__title"
                        >
                          Articles about
                          <a
                            class="article__entity"
                            (click)="
                              navigateToCompany(
                                chat.message.companyName,
                                chat.message.companyType
                              )
                            "
                          >
                            {{ chat.message.entityName }}
                          </a>
                        </h2>

                        <div
                          class="article__cards"
                          [ngClass]="{ wider: !chartVisible }"
                        >
                          <app-article-card
                            *ngFor="
                              let article of chat.message
                                .source_documents_events ||
                                chat.message.source_documents
                            "
                            [title]="article.title"
                            [publishTime]="article.publish_time"
                            [provider]="article.provider"
                            [eventNames]="article?.eventNames"
                            [entityNames]="article?.entityNames"
                            [citation]="article?.citation"
                            (click)="
                              showTheAricleView(
                                chat.message.companyName,
                                chat.message.companyType,
                                article
                              )
                            "
                          >
                          </app-article-card>
                        </div>
                      </div>
                    </div>

                    <!-- Feedback Box -->
                    <div
                      class="chat__feedbackbox"
                      [ngClass]="{
                        'always-visible': i === chatDetails.length - 1,
                      }"
                    >
                      <mat-icon
                        class="thumb-icon"
                        [ngClass]="{ active: chat.feedback === 1 }"
                        (click)="
                          chatFeedback(
                            parentMessageChatId,
                            chat.message_id,
                            'pos'
                          )
                        "
                      >
                        thumb_up
                      </mat-icon>
                      <mat-icon
                        class="thumb-icon"
                        [ngClass]="{ active: chat.feedback === -1 }"
                        (click)="
                          chatFeedback(
                            parentMessageChatId,
                            chat.message_id,
                            'neg'
                          )
                        "
                      >
                        thumb_down
                      </mat-icon>
                    </div>
                    <!-- Related Questions -->
                    <div *ngIf="i === chatDetails.length - 1">
                      <app-related-questions
                        [relatedQuestions]="
                          isAIMessage(chat.message) &&
                          chat.message.follow_up_questions
                        "
                        (submitRelatedQuestion)="submitRelatedQuestions($event)"
                      >
                      </app-related-questions>
                    </div>
                  </div>
                </div>

                <div
                  *ngIf="
                    chat.actor === 'ai' && shouldShowChatCharts(chat.message)
                  "
                >
                  <app-chat-charts
                    [isVisible]="chartVisible"
                    (visibilityChange)="updateChartVisibility($event)"
                    [childChat]="chat.message"
                  >
                  </app-chat-charts>
                </div>

                <div *ngIf="shouldShowOpenChartsIcon(chat.message)">
                  <div>
                    <div
                      class="charts__icon charts__icon--open"
                      id="chat-open-charts"
                      (click)="openAllCharts()"
                      (visibilityChange)="updateChartVisibility(true)"
                    >
                      <mat-icon>keyboard_arrow_right</mat-icon>
                    </div>
                  </div>
                </div>

                <div
                  *ngIf="
                    chat.actor === 'ai' && !shouldShowChatCharts(chat.message)
                  "
                >
                  <div
                    class="container__empty"
                    [ngClass]="{ 'closed-charts': !chartVisible }"
                  ></div>
                </div>
              </div>
            </ng-container>

            <div *ngIf="loading && !error" class="message__container">
              <div class="message__avatar-container">
                <div class="message__avatar message__avatar--response">
                  {{ llmAvatar }}
                </div>
              </div>
              <div class="message__textbox message__textbox--ai">
                <div class="loader"></div>
              </div>
            </div>
            <div
              class="message__textbox message__textbox--ai"
              *ngIf="i === chatDetails.length - 1 && error"
            >
              <p>Something went wrong, please try again.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="chat__input">
        <div
          class="chat__bottom-fade"
          [class.theme-light]="params?.theme === 'light'"
        ></div>
        <form class="chat__input-box" (ngSubmit)="onSubmit()">
          <input
            matInput
            [(ngModel)]="chatInput"
            type="email"
            placeholder="Type a message..."
            name="chat_input"
            class="chat__input-field"
          />
          <button mat-raised-button type="submit" id="chat-question-submit">
            <mat-icon>send</mat-icon>
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
