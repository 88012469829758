<div class="ilabel" *ngIf="label !== 'Search'">
  {{ label | ucfirst }}{{ label !== '' ? ' is:' : '' }}
</div>
<mat-form-field
  *ngIf="!['portfolio', 'query'].includes(label)"
  floatLabel="never"
  appearance="legacy"
  class="query2-search"
  [class.mat-form-field-invalid]="error"
>
  <input
    matInput
    autocomplete="off"
    placeholder="Search..."
    [formControl]="mySearch"
    [matAutocomplete]="menuQuery"
    (click)="inBlur(mySearch.value)"
    (input)="onSearchChange(mySearch.value)"
    matAutocompletePosition="below"
  />
  <mat-autocomplete
    #menuQuery="matAutocomplete"
    (optionSelected)="goSelect($event.option.value)"
    [autoActiveFirstOption]="true"
    class="searchLetter search-panel"
    panelWidth="auto"
    class="search-panel"
  >
    <div *ngIf="data.operator !== 'near'">
      <div *ngIf="items$ | async as search">
        <div
          *ngFor="
            let items of search.entity_prefix_search.hits
              | groupBy: 'entity.type'
              | orderByType
              | pairs
          "
        >
          <mat-optgroup>
            <div class="optgroup-search">
              <div class="indexo-left">{{ items[0] | nicetxt }}</div>
              <div class="indexo-right">
                <div class="optgroup-column column-1">Country</div>
                <div class="optgroup-column"># Articles</div>
              </div>
            </div>
            <ng-container *ngFor="let option of items[1] | orderBy: '-count'">
              <mat-option *ngIf="!option.entity.hasParent" [value]="option">
                <div class="search2-query">
                  <div class="indexo-left">
                    <span>{{ option.entity.name }}</span>
                    <span class="match">{{
                      option.entity.matched_form
                        ? option.entity.matched_form
                        : ''
                    }}</span>
                  </div>
                  <div class="indexo-right">
                    <div class="optgroup-column column-1">
                      {{
                        option.entity &&
                        option.entity.country &&
                        option.entity.country.alpha_id
                          ? option.entity.country.alpha_id.toUpperCase()
                          : '\u2014'
                      }}
                    </div>
                    <div class="optgroup-column">
                      {{ numberFormat(option.count) }}
                    </div>
                  </div>
                </div>
              </mat-option>
              <mat-option
                *ngFor="
                  let optionChild of option.entity.children | orderBy: '-count'
                "
                [value]="optionChild"
              >
                <div class="search2-query">
                  <div class="indexo-left">
                    <span class="subsidiary">{{
                      optionChild.entity.name
                    }}</span>
                    <span class="match">{{
                      optionChild.entity.matched_form
                        ? optionChild.entity.matched_form
                        : ''
                    }}</span>
                  </div>
                  <div class="indexo-right">
                    <div class="optgroup-column column-1">
                      {{
                        optionChild.entity &&
                        optionChild.entity.country &&
                        optionChild.entity.country.alpha_id
                          ? optionChild.entity.country.alpha_id.toUpperCase()
                          : '\u2014'
                      }}
                    </div>
                    <div class="optgroup-column">
                      {{ numberFormat(optionChild.count) }}
                    </div>
                  </div>
                </div>
              </mat-option>
            </ng-container>
          </mat-optgroup>
        </div>
        <mat-optgroup label="Event" *ngIf="search.event_search.hits.length">
          <mat-option
            *ngFor="let option of search.event_search.hits"
            [value]="{ type: 'event', option: option }"
          >
            <div class="search2-query">
              <div class="indexo-left">
                <span>{{ option.name }}</span>
              </div>
            </div>
          </mat-option>
        </mat-optgroup>
        <mat-optgroup *ngIf="search.source_prefix_search.hits.length">
          <div class="optgroup-search">
            <div class="indexo-left">Source</div>
            <div class="indexo-right">
              <div class="optgroup-column column-1"></div>
              <div class="optgroup-column"># Articles</div>
            </div>
          </div>
          <mat-option
            *ngFor="
              let option of search.source_prefix_search.hits | orderBy: '-name'
            "
            [value]="{ type: 'source', option: option }"
          >
            <div class="search2-query">
              <div class="indexo-left">
                <span>{{ option.name }}</span>
                <span class="match">{{ option.url ? option.url : '' }}</span>
              </div>
              <div class="indexo-right">
                <div class="optgroup-column column-1"></div>
                <div class="optgroup-column">
                  {{
                    option.sources[0]?.doc_count !== null
                      ? numberFormat(option.sources[0]?.doc_count)
                      : ''
                  }}
                </div>
              </div>
            </div>
          </mat-option>
          <mat-option
            *ngFor="
              let option of search.source_url_search.hits | orderBy: '-name'
            "
            [value]="{ type: 'source', option: option }"
          >
            <div class="search2-query">
              <div class="indexo-left">
                <span>{{ option.name }}</span>
                <span class="match">{{ option.url ? option.url : '' }}</span>
              </div>
              <div class="indexo-right">
                <div class="optgroup-column column-1"></div>
                <div class="optgroup-column">
                  {{
                    option.sources[0]?.doc_count !== null
                      ? numberFormat(option.sources[0]?.doc_count)
                      : ''
                  }}
                </div>
              </div>
            </div>
          </mat-option>
        </mat-optgroup>
      </div>
      <mat-optgroup label="Free text" *ngIf="mySearch.value !== ''">
        <mat-option [value]="{ type: 'freetext', name: mySearch.value }">
          <div class="search2-query">
            <div class="indexo-left">{{ mySearch.value }}</div>
          </div>
        </mat-option>
      </mat-optgroup>
    </div>
    <div *ngIf="data.operator === 'near'">
      <mat-optgroup label="Free text" *ngIf="mySearch.value !== ''">
        <mat-option [value]="{ type: 'word', name: mySearch.value }">
          <div class="search2-query">
            <div class="indexo-left">{{ mySearch.value }}</div>
          </div>
        </mat-option>
      </mat-optgroup>
    </div>
  </mat-autocomplete>
</mat-form-field>

<mat-form-field
  *ngIf="label === 'portfolio'"
  floatLabel="never"
  appearance="legacy"
  class="query2-search"
  [class.mat-form-field-invalid]="error"
>
  <mat-label>Select embedded portfolio...</mat-label>
  <mat-select
    [(value)]="selectedArtifact"
    (selectionChange)="selectArtifact($event.value, 'portfolio')"
    required
  >
    <mat-option
      *ngFor="let option of folio$ | async | orderBy: 'name'"
      [value]="option.uid"
      >{{ option.name }}</mat-option
    >
  </mat-select>
</mat-form-field>

<mat-form-field
  *ngIf="label === 'query'"
  floatLabel="never"
  appearance="legacy"
  class="query2-search"
  [class.mat-form-field-invalid]="error"
>
  <mat-label>Select embedded query...</mat-label>
  <mat-select
    [(value)]="selectedArtifact"
    (selectionChange)="selectArtifact($event.value, 'query')"
    required
  >
    <mat-select-trigger>{{
      queryNameFromId(selectedArtifact)
    }}</mat-select-trigger>
    <mat-option
      disabled
      *ngIf="auth.featureFlags.linkQueries"
      [class.option-query]="auth.featureFlags.linkQueries"
    >
      <span class="option-name">Name</span>
      <span class="option-description">Description</span>
    </mat-option>
    <mat-option
      *ngFor="let option of query$ | async | orderBy: 'name'"
      [value]="option.uid"
      [class.option-query]="auth.featureFlags.linkQueries"
    >
      <span
        [class.option-name]="auth.featureFlags.linkQueries"
        matTooltip="{{ option.name }}"
        >{{ option.name }}</span
      >
      <span
        class="option-description"
        matTooltip="{{ option.description }}"
        *ngIf="auth.featureFlags.linkQueries"
        >{{ option.description }}</span
      >
    </mat-option>
  </mat-select>
</mat-form-field>
