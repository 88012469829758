<div mat-dialog-title class="report-title">{{data.titleLine1}}:</div>
<div mat-dialog-title class="report-title">{{data.titleLine2}}</div>
<div mat-dialog-content class="report-content" *ngIf="data.hasOwnProperty('portfolioName')">
    <div class="report-thumbnail">
      <!--<img src="../../../assets/images/portfolio_report_teaser.png">-->
      <img *ngIf="(data.type === 'general_risk')" src="../../../assets/images/GR_portfolio_report_thumbnail.png">
      <img *ngIf="(data.type === 'immediate_risk')" src="../../../assets/images/IR_portfolio_report_thumbnail.png">
      <img *ngIf="(data.type === 'eb_sim')" src="../../../assets/images/EBSIM_portfolio_report_thumbnail.png">
      <img *ngIf="(data.type === 'eb_sim_risk')" src="../../../assets/images/EBSIMR_portfolio_report_thumbnail.png">
      <img *ngIf="(data.type === 'esg')" src="../../../assets/images/ESG_portfolio_report_thumbnail.png">
      <img *ngIf="(data.type === 'esg_risk')" src="../../../assets/images/ESGR_portfolio_report_thumbnail.png">
      <img *ngIf="(data.type === 'credit_risk')" src="../../../assets/images/CR_portfolio_report_thumbnail.png">
    </div>
    <div class="report-info">
      <p *ngIf="(data.type === 'general_risk')">
        Broaden your risk perspective with our General Risk Score Portfolio Report: Explore vital insights into Top Events, Score Trends, Top Movers and Industry Benchmarks.
      </p>
      <p *ngIf="(data.type === 'immediate_risk')">
        Navigate risks swiftly with our Immediate Risk Score Portfolio Report: Access vital insights into Top Events, Score Trends, Top Movers and Industry Benchmarks.
      </p>
      <p *ngIf="(data.type === 'eb_sim')">
        Enhance decision-making with our custom EB-SIM Score Portfolio Report, a Summary of Top Events, Score Trends, Top Movers and Industry Benchmarks.
      </p>
      <p *ngIf="(data.type === 'eb_sim_risk')">
        Enhance decision-making with our custom EB-SIM Risk Score Portfolio Report, a Summary of Top Events, Score Trends, Top Movers and Industry Benchmarks.
      </p>
      <p *ngIf="(data.type === 'esg')">
        Stay ahead of the ESG curve. Our Portfolio Report unveils vital insights into Top Events, Score Trends, Top Movers and Industry Benchmarks.
      </p>
      <p *ngIf="(data.type === 'esg_risk')">
        Our ESG Risk Portfolio Report unveils vital insights into Top Events, Score Trends, Top Movers and Industry Benchmarks.
      </p>
      <p *ngIf="(data.type === 'credit_risk')">
        Master Credit Risk analysis with our comprehensive Portfolio Report: Unearth vital insights into Top Events, Score Trends, Top Movers and Industry Benchmarks.
      </p>
    </div>
</div>
<div mat-dialog-content class="report-content" *ngIf="!data.hasOwnProperty('portfolioName')">
  <div class="report-thumbnail">
    <img *ngIf="(data.type === 'general_risk')" src="../../../assets/images/GR_report_thumbnail.png">
    <img *ngIf="(data.type === 'immediate_risk')" src="../../../assets/images/IR_report_thumbnail.png">
    <img *ngIf="(data.type === 'eb_sim')" src="../../../assets/images/EBSIM_report_thumbnail.png">
    <img *ngIf="(data.type === 'eb_sim_risk')" src="../../../assets/images/EBSIMR_report_thumbnail.png">
    <img *ngIf="(data.type === 'esg')" src="../../../assets/images/ESG_report_thumbnail.png">
    <img *ngIf="(data.type === 'esg_risk')" src="../../../assets/images/ESGR_report_thumbnail.png">
    <img *ngIf="(data.type === 'credit_risk')" src="../../../assets/images/CR_report_thumbnail.png">
  </div>
  <div class="report-info">
    <p *ngIf="(data.type === 'general_risk')">
      Broaden your risk perspective with our General Risk Score Report: Explore essential insights into Overall Score Trends, Key Risk Indicators and Peer Group Comparisons.
    </p>
    <p *ngIf="(data.type === 'immediate_risk')">
      Navigate risks swiftly with our Immediate Risk Score Report: Access key insights into Urgent Risk Factors, Score Dynamics, and Peer Group comparisons.
    </p>
    <p *ngIf="(data.type === 'eb_sim')">
      Enhance decision-making with our custom EB-SIM Score Report, a comprehensive Summary of Score Trends, Major Events and Peer Group Comparisons.
    </p>
    <p *ngIf="(data.type === 'eb_sim_risk')">
      Enhance decision-making with our custom EB-SIM Risk Score Report, a comprehensive Summary of Score Trends, Major Events and Peer Group Comparisons.
    </p>
    <p *ngIf="(data.type === 'esg')">
      Stay ahead of the ESG curve. Our Company Report unveils Top Stories, Score Trends, and Industry Benchmarks, offering unparalleled insights into companies' ESG Exposure.
    </p>
    <p *ngIf="(data.type === 'esg_risk')">
      Our ESG Risk Company Report unveils Top Stories, Score Trends, and Industry Benchmarks, offering unparalleled insights into companies' ESG Risk Exposure.
    </p>
    <p *ngIf="(data.type === 'credit_risk')">
      Master Credit Risk analysis with our comprehensive Report: Unearth vital insights into Top Risks, Score Trends, and Industry Benchmarks for strategic advantage.
    </p>
  </div>
</div>
<div mat-dialog-title class="report-message" *ngIf="data.messageLine">
  <mat-icon>warning</mat-icon>
  {{data.messageLine}}
</div>
<div mat-dialog-actions class="report-actions">
    <button mat-button [disabled]="reportStatus === 'download_quota_exceeded'" [class.report-disabled]="reportStatus === 'download_quota_exceeded'" [matTooltip]="(reportStatus === 'download_quota_exceeded') ? 'Monthly Report Download Limit Exceeded' : ''" (click)="onClick()">Get the Report</button>
    <!--<button mat-button *ngIf="reportStatus === 'available'" (click)="onClick2(reportUrl)" matTooltip="Report was already generated, click to open report in a new tab">Get the Report</button>-->
</div>
